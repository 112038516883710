import { Link } from "gatsby"
import React, { Component } from "react"
import Button from "../../components/base/Button"
import Footer from "../../components/base/Footer"
import Header from "../../components/base/Header"
import SEO from "../../components/base/seo"
import Hero from "../../components/marketing/Hero"
import RightContentCard from "../../components/marketing/RightContentCard"
import SubscribeAction from "../../components/marketing/SubscribeAction"
import Whatsapp from "../../components/marketing/Whatsapp"
import GHCAbanner from "../../images/banner_ghca.jpg"
import MRCSbanner from "../../images/banner_mrcs.jpg"
import WAWbanner from "../../images/banner_waw.jpg"
import YCKbanner from "../../images/banner_yck.jpg"
import NGOdonate from "../../images/ngo_donate.jpg"
import MockUp from "../../images/ngo_mockup.png"
import { Language } from "../../store/reducers/utilsReducer"
import { localeContent } from "../../utils/locale"

class NgoInitiative extends Component {
  renderSocialLinks = (website: string, facebook: string, insta: string) => {
    return (
      <div className="flex flex-row">
        <a
          href={website}
          target="_blank"
          rel="noreferrer"
          className="text-gray-400 hover:text-gray-500"
        >
          <svg
            className="w-5 h-5 mt-3"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              fill-rule="evenodd"
              d="M4.083 9h1.946c.089-1.546.383-2.97.837-4.118A6.004 6.004 0 004.083 9zM10 2a8 8 0 100 16 8 8 0 000-16zm0 2c-.076 0-.232.032-.465.262-.238.234-.497.623-.737 1.182-.389.907-.673 2.142-.766 3.556h3.936c-.093-1.414-.377-2.649-.766-3.556-.24-.56-.5-.948-.737-1.182C10.232 4.032 10.076 4 10 4zm3.971 5c-.089-1.546-.383-2.97-.837-4.118A6.004 6.004 0 0115.917 9h-1.946zm-2.003 2H8.032c.093 1.414.377 2.649.766 3.556.24.56.5.948.737 1.182.233.23.389.262.465.262.076 0 .232-.032.465-.262.238-.234.498-.623.737-1.182.389-.907.673-2.142.766-3.556zm1.166 4.118c.454-1.147.748-2.572.837-4.118h1.946a6.004 6.004 0 01-2.783 4.118zm-6.268 0C6.412 13.97 6.118 12.546 6.03 11H4.083a6.004 6.004 0 002.783 4.118z"
            />
          </svg>
        </a>
        <a
          href={facebook}
          target="_blank"
          rel="noreferrer"
          className="text-gray-400 hover:text-gray-500"
        >
          <svg
            className="w-5 h-5 mt-3 ml-2"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 30 30"
          >
            <path
              fill-rule="evenodd"
              d="M24,4H6C4.895,4,4,4.895,4,6v18c0,1.105,0.895,2,2,2h10v-9h-3v-3h3v-1.611C16,9.339,17.486,8,20.021,8 c1.214,0,1.856,0.09,2.16,0.131V11h-1.729C19.376,11,19,11.568,19,12.718V14h3.154l-0.428,3H19v9h5c1.105,0,2-0.895,2-2V6 C26,4.895,25.104,4,24,4z"
            />
          </svg>
        </a>
        <a
          href={insta}
          target="_blank"
          rel="noreferrer"
          className="text-gray-400 hover:text-gray-500"
        >
          <svg
            className="w-5 h-5 mt-3 ml-2"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              fill-rule="evenodd"
              d="M 8 3 C 5.243 3 3 5.243 3 8 L 3 16 C 3 18.757 5.243 21 8 21 L 16 21 C 18.757 21 21 18.757 21 16 L 21 8 C 21 5.243 18.757 3 16 3 L 8 3 z M 8 5 L 16 5 C 17.654 5 19 6.346 19 8 L 19 16 C 19 17.654 17.654 19 16 19 L 8 19 C 6.346 19 5 17.654 5 16 L 5 8 C 5 6.346 6.346 5 8 5 z M 17 6 A 1 1 0 0 0 16 7 A 1 1 0 0 0 17 8 A 1 1 0 0 0 18 7 A 1 1 0 0 0 17 6 z M 12 7 C 9.243 7 7 9.243 7 12 C 7 14.757 9.243 17 12 17 C 14.757 17 17 14.757 17 12 C 17 9.243 14.757 7 12 7 z M 12 9 C 13.654 9 15 10.346 15 12 C 15 13.654 13.654 15 12 15 C 10.346 15 9 13.654 9 12 C 9 10.346 10.346 9 12 9 z"
            />
          </svg>
        </a>
      </div>
    )
  }

  renderNgoItems = () => {
    const language = Language.BM
    const ngoItems = [
      {
        name: localeContent(language).ngoInitiative.ngoFooter.ghcaTitle,
        beneficiary: localeContent(language).ngoInitiative.ngoFooter.ghcaType,
        description: localeContent(language).ngoInitiative.ngoFooter
          .ghcaDescription,
        image: GHCAbanner,
        website: "https://www.greatheartcharity.org.my",
        facebook: "https://www.facebook.com/greatheartcharityassociation/",
        insta: "https://www.instagram.com/greatheartcharity/",
      },
      {
        name: localeContent(language).ngoInitiative.ngoFooter.mrcsTitle,
        beneficiary: localeContent(language).ngoInitiative.ngoFooter.mrcsType,
        description: localeContent(language).ngoInitiative.ngoFooter
          .mrcsDescription,
        image: MRCSbanner,
        website: "https://www.redcrescent.org.my",
        facebook: "https://www.facebook.com/MalaysianRC/",
        insta: "https://www.instagram.com/malaysianredcrescent/",
      },
      {
        name: localeContent(language).ngoInitiative.ngoFooter.wawTitle,
        beneficiary: localeContent(language).ngoInitiative.ngoFooter.wawType,
        description: localeContent(language).ngoInitiative.ngoFooter
          .wawDescription,
        image: WAWbanner,
        website: "https://whatawaste.my",
        facebook: "https://www.facebook.com/whatawasteMY/",
        insta: "https://www.instagram.com/whatawaste.my/",
      },
      {
        name: localeContent(language).ngoInitiative.ngoFooter.yckTitle,
        beneficiary: localeContent(language).ngoInitiative.ngoFooter.yckType,
        description: localeContent(language).ngoInitiative.ngoFooter
          .yckDescription,
        image: YCKbanner,
        website: "https://yck.org.my",
        facebook: "https://www.facebook.com/YayasanChowKit/",
        insta: "https://www.instagram.com/theyayasanchowkit/",
      },
    ]
    const ngoList: any[] = []
    ngoItems.map((eachNgo, index) => {
      ngoList.push(
        <li key={index}>
          <div className="space-y-4">
            <div className="aspect-w-2 aspect-h-1">
              <img
                className="object-cover shadow-lg rounded-lg"
                src={eachNgo.image}
                alt=""
              />
            </div>
            <div className="text-lg leading-6 font-medium space-y-1">
              <h3>{eachNgo.name}</h3>
              <div className="flex flex-wrap flex-row my-2">
                <div className="bg-arusgreen-600 text-white rounded mx-0.5">
                  <div className="flex flex-row p-1 text-xs">
                    {eachNgo.beneficiary}
                  </div>
                </div>
              </div>
            </div>
            <div className="text-base">
              <p className="text-gray-500 text-justify">
                {eachNgo.description}
              </p>
            </div>
            {this.renderSocialLinks(
              eachNgo.website,
              eachNgo.facebook,
              eachNgo.insta
            )}
          </div>
        </li>
      )
      return null
    })

    return ngoList
  }

  render() {
    const language = Language.BM
    return (
      <div className="bg-white">
        <Whatsapp />
        <SEO
          title="Arus Oil - Derma kepada Organisasi Amal"
          description="Arus Oil memperkasakan komuniti tempatan di Malaysia untuk menyumbang kepada badan amal dengan mendermakan pulangan tunai tambahan mereka yang diperoleh daripada mengitar semula minyak masak terpakai. Menderma sekarang untuk membuat perubahan!"
        />
        <Header page={"Inisiatif Kami"} />
        <Hero
          title={
            localeContent(language).ngoInitiative.ngoTopTitle.heroTitleBlack
          }
          content={
            localeContent(language).ngoInitiative.ngoTopTitle.description
          }
          image={MockUp}
          callToAction={
            <div className="mt-4 sm:mt-0">
              <Link to="/auth/signUp">
                <Button
                  className="w-full"
                  type="normal"
                  text={
                    localeContent(language).ngoInitiative.ngoTopTitle
                      .ngoGetStarted
                  }
                />
              </Link>
            </div>
          }
          language={language}
          size={"small"}
        />
        <div className=" bg-gray-50 pb-1 mb-10 ">
          <RightContentCard
            image={NGOdonate}
            title={localeContent(language).ngoInitiative.ngoTopTitle.ngoTitle2}
            content={
              localeContent(language).ngoInitiative.ngoTopTitle.ngoDescription2
            }
          />
        </div>

        <div className="relative max-w-3xl mx-auto px-4 sm:px-0 lg:max-w-7xl">
          <div className="text-center relative max-w-6xl mx-auto px-4">
            <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
              {localeContent(language).ngoInitiative.ngoFooter.ngoTitle1}
            </h2>
            <div className="mt-2 mx-auto text-base text-justify leading-7 text-gray-500 whitespace-pre-line">
              {localeContent(language).ngoInitiative.ngoFooter.ngoCaption1}
            </div>
          </div>
          <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 ">
            <ul className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:gap-x-8">
              {this.renderNgoItems()}
            </ul>
          </div>
        </div>

        <SubscribeAction language={language} />
        <Footer />
      </div>
    )
  }
}

export default NgoInitiative
